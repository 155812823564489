import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import Swiper from 'swiper';
import 'aos/dist/aos.css';
// import 'swiper/swiper-bundle.min.css';

function App() {
const [isLightMode, setIsLightMode] = useState(false)

  useEffect(() => {
    // Preloader Logic
    const preloader = document.querySelector('.preloader');
    if (preloader) {
      preloader.style.transition = 'opacity 0.5s ease';
      setTimeout(() => {
        preloader.style.opacity = '0';
        setTimeout(() => {
          preloader.style.display = 'none';
        }, 500);
      }, 1000);
    }

    // Light and Dark Theme Logic
    const colorSwitcher = document.getElementById('btnSwitch');
    const updateThemeColor = (themeMode = 'light') => {
      document.documentElement.setAttribute('data-bs-theme', themeMode);
      localStorage.setItem('theme', themeMode);
      if (themeMode === 'dark') {
        colorSwitcher.classList.add('dark-switcher');
      } else {
        colorSwitcher.classList.remove('dark-switcher');
      }
      changeImage(themeMode);
    };

    const switchThemeByUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const theme = urlParams.get('theme');
      if (theme) {
        localStorage.setItem('theme', theme);
      }
    };

    const changeImage = (themeMode = 'light') => {
      const icon = document.querySelector('#btnSwitch img');
      if (themeMode === 'dark') {
        icon.src = '/assets/images/icon/sun.svg';
        const images = document.querySelectorAll('img.dark');
        images.forEach(image => {
          const oldSrc = image.src.replace('.', '.');
          const newSrc = oldSrc.replace('.', '.');
          image.src = newSrc;
        });
      } else {
        icon.src = '/assets/images/icon/moon.svg';
        const images = document.querySelectorAll('img.dark');
        images.forEach(image => {
          const newSrc = image.src.replace('.', '.');
          image.src = newSrc;
        });
      }
    };

    switchThemeByUrl();
    updateThemeColor(localStorage.getItem('theme'));

    colorSwitcher.addEventListener('click', () => {
      const theme = localStorage.getItem('theme');
      updateThemeColor(theme === 'dark' ? 'light': 'dark' );
    });

    // Header Logic
    const submenuList = document.querySelectorAll('ul>li>.submenu');
    submenuList.forEach(submenu => {
      const parentLi = submenu.parentElement;
      if (parentLi) {
        parentLi.classList.add('menu-item-has-children');
      }
    });

    const menuList = document.querySelectorAll('ul');
    menuList.forEach(menu => {
      const parentLi = menu.parentElement;
      if (parentLi) {
        parentLi.addEventListener('mouseover', () => {
          const menuPos = menu.getBoundingClientRect();
          if (menuPos.left + menu.offsetWidth > window.innerWidth) {
            menu.style.left = -menu.offsetWidth + 'px';
          }
        });
      }
    });

    const menuLinks = document.querySelectorAll('.menu li a');
    menuLinks.forEach(link => {
      link.addEventListener('click', (e) => {
        e.stopPropagation();
        const element = link.parentElement;
        if (window.innerWidth < 1200) {
          if (element.classList.contains('open')) {
            element.classList.remove('open');
            element.querySelector('ul').style.display = 'none';
          } else {
            element.classList.add('open');
            element.querySelector('ul').style.display = 'block';
          }
        }
      });
    });

    const headerBar = document.querySelector('.header-bar');
    const menu = document.querySelector('.menu');
    if (headerBar && menu) {
      headerBar.addEventListener('click', () => {
        headerBar.classList.toggle('active');
        menu.classList.toggle('active');
      });
    }

    const fixedTop = document.querySelector('header');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        fixedTop.classList.add('header-fixed', 'fadeInUp');
      } else {
        fixedTop.classList.remove('header-fixed', 'fadeInUp');
      }
    });

    // Initialize AOS
    AOS.init();

    // Initialize Swipers
    new Swiper('.partner__slider', {
      spaceBetween: 24,
      grabCursor: true,
      loop: true,
      slidesPerView: 2,
      breakpoints: {
        576: { slidesPerView: 3 },
        768: { slidesPerView: 4 },
        992: { slidesPerView: 5, spaceBetween: 15 },
        1200: { slidesPerView: 6, spaceBetween: 25 },
      },
      autoplay: { delay: 1, disableOnInteraction: true },
      speed: 2000,
    });

    new Swiper('.blog__slider', {
      spaceBetween: 24,
      grabCursor: true,
      loop: true,
      slidesPerView: 1,
      breakpoints: {
        576: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        992: { slidesPerView: 3 },
        1200: { slidesPerView: 3 },
      },
      autoplay: true,
      speed: 500,
      navigation: { nextEl: '.blog__slider-next', prevEl: '.blog__slider-prev' },
    });

    new Swiper('.testimonial__slider', {
      spaceBetween: 24,
      grabCursor: true,
      loop: true,
      slidesPerView: 1,
      breakpoints: {
        576: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        992: { slidesPerView: 2 },
        1200: { slidesPerView: 2, spaceBetween: 25 },
      },
      autoplay: true,
      speed: 500,
      navigation: { nextEl: '.testimonial__slider-next', prevEl: '.testimonial__slider-prev' },
    });

    new Swiper('.testimonial__slider2', {
      spaceBetween: 24,
      grabCursor: true,
      loop: true,
      slidesPerView: 1,
      breakpoints: {
        576: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        992: { slidesPerView: 3 },
        1200: { slidesPerView: 3, spaceBetween: 25 },
      },
      autoplay: true,
      speed: 500,
      navigation: { nextEl: '.testimonial__slider-next', prevEl: '.testimonial__slider-prev' },
    });

    new Swiper('.testimonial__slider3', {
      spaceBetween: 24,
      grabCursor: true,
      loop: true,
      slidesPerView: 1,
      autoplay: true,
      speed: 500,
    });

    new Swiper('.roadmap__slider', {
      grabCursor: true,
      slidesPerView: 1,
      breakpoints: {
        576: { slidesPerView: 1, spaceBetween: 15 },
        768: { slidesPerView: 2, spaceBetween: 15 },
        992: { slidesPerView: 3, spaceBetween: 10 },
        1200: { slidesPerView: 4, spaceBetween: 10 },
        1400: { slidesPerView: 4, spaceBetween: 10 },
      },
      autoplay: true,
      speed: 500,
    });

    // Scroll to Top Logic
    const scrollToTop = document.querySelector('.scrollToTop');
    window.addEventListener('scroll', () => {
      if (scrollToTop) {
        if (window.pageYOffset > 300) {
          scrollToTop.style.bottom = '7%';
          scrollToTop.style.opacity = '1';
          scrollToTop.style.transition = 'all .5s ease';
        } else {
          scrollToTop.style.bottom = '-30%';
          scrollToTop.style.opacity = '0';
          scrollToTop.style.transition = 'all .5s ease';
        }
      }
    });

    if (scrollToTop) {
      scrollToTop.addEventListener('click', (e) => {
        e.preventDefault();
        const scrollDuration = 100;
        const scrollStep = -window.scrollY / (scrollDuration / 15);
        const scrollInterval = setInterval(() => {
          if (window.scrollY !== 0) {
            window.scrollBy(0, scrollStep);
          } else {
            clearInterval(scrollInterval);
          }
        }, 15);
      });
    }

    // RTL Icon Direction Change Logic
    const htmlTag = document.querySelector('html');
    const toggleAllIconsDirection = () => {
      const icons = document.querySelectorAll('i');
      icons.forEach((icon) => {
        if (icon.classList.contains('fa-arrow-right') || icon.classList.contains('fa-angle-right')) {
          icon.classList.remove('fa-arrow-right', 'fa-angle-right');
          icon.classList.add('fa-arrow-left', 'fa-angle-left');
        } else if (icon.classList.contains('fa-arrow-left') || icon.classList.contains('fa-angle-left')) {
          icon.classList.remove('fa-arrow-left', 'fa-angle-left');
          icon.classList.add('fa-arrow-right', 'fa-angle-right');
        }
      });
    };

    if (htmlTag.getAttribute('dir') === 'rtl') {
      toggleAllIconsDirection();
    }
  }, [isLightMode]);

  return (
    <div>
      {/* ===============>> Preloader start here <<================= */}
      <div className="preloader">
        <img src="/assets/images/logo/preloader.png" alt="preloader icon" />
      </div>
      {/* ===============>> Preloader end here <<================= */}
      {/* ===============>> light&dark switch start here <<================= */}
      <div className="lightdark-switch" style={{display:"none"}}>
        <span className="switch-btn" id="btnSwitch">
          <img
            src="/assets/images/icon/moon.svg"
            alt="light-switchbtn"
            className="swtich-icon"
            style={{display:"none"}}
          />
        </span>
      </div>
      {/* ===============>> light&dark switch start here <<================= */}
      {/* ===============>> Header section start here <<================= */}
      <header className="header-section header-section--style2">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                <a href="index-2.html">
                  <img
                    className="dark"
                    src="/assets/images/logo/logo.png"
                    alt="logo"
                  />
                </a>
              </div>
              {/* <div className="menu-area">
                <ul className="menu menu--style1">
                  <li className="megamenu">
                    <a href="#">Demos </a>
                    <ul className="submenu">
                      <li>
                        <div className="home-showcase">
                          <div className="row g-4 row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                            <div className="col order-first">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/1.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index6a08.html?theme=light"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  <a href="index6a08.html?theme=light">
                                    Stock Trading
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/1.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index4965.html?theme=dark"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  <a href="index4965.html?theme=dark">
                                    Stock Trading Dark
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/2.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index-26a08.html?theme=light"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  {" "}
                                  <a href="index-26a08.html?theme=light">
                                    Crypto Trading
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/2.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index-24965.html?theme=dark"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  {" "}
                                  <a href="index-24965.html?theme=dark">
                                    Crypto Trading Dark
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/3.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index-36a08.html?theme=light"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  <a href="index-36a08.html?theme=light">
                                    Forex Trading
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/3.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index-34965.html?theme=dark"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  <a href="index-34965.html?theme=dark">
                                    Forex Trading Dark
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/4.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__badge">
                                    <span>New</span>
                                  </div>
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index-46a08.html?theme=light"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  <a href="index-46a08.html?theme=light">
                                    Day Trading
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/4.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__badge">
                                    <span>New</span>
                                  </div>
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index-44965.html?theme=dark"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  <a href="index-44965.html?theme=dark">
                                    Day Trading Dark
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/5.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__badge">
                                    <span>New</span>
                                  </div>
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index-56a08.html?theme=light"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  <a href="index-56a08.html?theme=light">
                                    Trading Platform
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/5.png"
                                    alt="home-showcase"
                                  />
                                  <div className="home-showcase__badge">
                                    <span>New</span>
                                  </div>
                                  <div className="home-showcase__buttons">
                                    <a
                                      href="index-54965.html?theme=dark"
                                      className="trk-btn trk-btn--primary home-showcase__buttons-item mt-3"
                                    >
                                      <span>Preview</span>
                                    </a>
                                  </div>
                                </div>
                                <h3 className="home-showcase__title">
                                  <a href="index-54965.html?theme=dark">
                                    Trading Platform Dark
                                  </a>
                                </h3>
                              </div>
                            </div>
                            <div className="col order-last">
                              <div className="home-showcase__item">
                                <div className="home-showcase__image">
                                  <img
                                    src="/assets/images/demo/new.png"
                                    alt="home-showcase"
                                  />
                                </div>
                                <h3 className="home-showcase__title">
                                  {" "}
                                  <a href="#">New Demos</a>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Services</a>
                    <ul className="submenu">
                      <li>
                        <a href="services.html">Services</a>
                      </li>
                      <li>
                        <a href="service-details.html">Services Details</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">About</a>
                    <ul className="submenu">
                      <li>
                        <a href="about.html">About Us</a>
                      </li>
                      <li>
                        <a href="price.html">Price</a>
                      </li>
                      <li>
                        <a href="team.html">Team</a>
                      </li>
                      <li>
                        <a href="team-2.html">Team 2</a>
                      </li>
                      <li>
                        <a href="team-details.html">Team Details</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Pages</a>
                    <ul className="submenu">
                      <li>
                        <a href="blogs.html">Blogs</a>
                      </li>
                      <li>
                        <a href="blog-sidebar.html">Blog - Side Bar</a>
                      </li>
                      <li>
                        <a href="blog-details.html">Blog Details</a>
                      </li>
                      <li>
                        <a href="signup.html">Sign Up</a>
                      </li>
                      <li>
                        <a href="signup-2.html">Sign Up 2</a>
                      </li>
                      <li>
                        <a href="signin.html">Sign In</a>
                      </li>
                      <li>
                        <a href="signin-2.html">Sign In 2</a>
                      </li>
                      <li>
                        <a href="forgot-pass.html">Reset Password</a>
                      </li>
                      <li>
                        <a href="forgot-pass-2.html">Reset Password 2</a>
                      </li>
                      <li>
                        <a href="404.html">404 Error</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                </ul>
              </div> */}
              <div className="header-action">
                <div className="menu-area">
                  <div className="header-btn">
                    <a
                      href="signup.html"
                      className="trk-btn trk-btn--border trk-btn--primary"
                    >
                      <span>Join Now</span>
                    </a>
                  </div>
                  {/* toggle icons */}
                  <div className="header-bar d-lg-none header-bar--style1">
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* ===============>> Header section end here <<================= */}
      {/* ===============>> Banner section start here <<================= */}
      <section className="banner banner--style1">
        <div className="banner__bg">
          <div className="banner__bg-element">
            <img
              src="/assets/images/banner/home1/bg.png"
              alt="section-bg-element"
              className="dark d-none d-lg-block"
            />
            <span className="bg-color d-lg-none" />
          </div>
        </div>
        <div className="container">
          <div className="banner__wrapper">
            <div className="row gy-5 gx-4">
              <div className="col-lg-6 col-md-7">
                <div
                  className="banner__content"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  <div className="banner__content-coin">
                    <img src="/assets/images/banner/home1/3.png" alt="coin icon" />
                  </div>
                  <h1 className="banner__content-heading">
                    Invest your money with <span>higher return</span>
                  </h1>
                  <p className="banner__content-moto">
                    Anyone can invest money to different currency to increase their
                    earnings by the help of Bitrader through online.
                  </p>
                  <div className="banner__btn-group btn-group">
                    <a
                      href="signin.html"
                      className="trk-btn trk-btn--primary trk-btn--arrow"
                    >
                      Get Started
                      <span>
                        <i className="fa-solid fa-arrow-right" />
                      </span>{" "}
                    </a>
                    <a
                      href="https://www.youtube.com/watch?v=MHhIzIgFgJo&ab_channel=NoCopyrightFootages"
                      className="trk-btn trk-btn--outline22"
                      data-fslightbox=""
                    >
                      <span className="style1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1397_814)">
                            <path
                              d="M10.5547 7.03647C9.89015 6.59343 9 7.06982 9 7.86852V16.1315C9 16.9302 9.89015 17.4066 10.5547 16.9635L16.7519 12.8321C17.3457 12.4362 17.3457 11.5638 16.7519 11.1679L10.5547 7.03647Z"
                              stroke="#A4FD5"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <rect
                            x="-0.75"
                            y="0.75"
                            width="22.5"
                            height="22.5"
                            rx="11.25"
                            transform="matrix(-1 0 0 1 22.5 0)"
                            stroke="#A4FD5"
                            strokeWidth="1.5"
                          />
                          <defs>
                            <clipPath id="clip0_1397_814">
                              <rect width={24} height={24} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>{" "}
                      Watch Video
                    </a>
                  </div>
                  <div className="banner__content-social">
                    <p>Follow Us</p>
                    <ul className="social">
                      <li className="social__item">
                        <a
                          href="#"
                          className="social__link social__link--style1 active"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li className="social__item">
                        <a href="#" className="social__link social__link--style1">
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </li>
                      <li className="social__item">
                        <a href="#" className="social__link social__link--style1">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                      <li className="social__item">
                        <a href="#" className="social__link social__link--style1">
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                      <li className="social__item">
                        <a
                          href="signin.html"
                          className="social__link social__link--style1"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-5">
                <div
                  className="banner__thumb"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img
                    src="/assets/images/banner/home1/1.png"
                    alt="banner-thumb"
                    className="dark"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner__shape">
          <span className="banner__shape-item banner__shape-item--1">
            <img src="/assets/images/banner/home1/4.png" alt="shape icon" />
          </span>
        </div>
      </section>
      {/* ===============>> Banner section end here <<================= */}
      {/* ===============>> partner section start here <<================= */}
      <div className="partner partner--gradient">
        <div className="container">
          <div className="partner__wrapper">
            <div className="partner__slider swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="partner__item">
                    <div className="partner__item-inner">
                      <img
                        src="/assets/images/partner/light/1.png"
                        alt="partner logo"
                        className="dark"
                      />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="partner__item">
                    <div className="partner__item-inner">
                      <img
                        src="/assets/images/partner/light/2.png"
                        alt="partner logo"
                        className="dark"
                      />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="partner__item">
                    <div className="partner__item-inner">
                      <img
                        src="/assets/images/partner/light/3.png"
                        alt="partner logo"
                        className="dark"
                      />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="partner__item">
                    <div className="partner__item-inner">
                      <img
                        src="/assets/images/partner/light/4.png"
                        alt="partner logo"
                        className="dark"
                      />
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="partner__item">
                    <div className="partner__item-inner">
                      <img
                        src="/assets/images/partner/light/5.png"
                        alt="partner logo"
                        className="dark"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============>> partner section end here <<================= */}
      {/* ===============>> About section start here <<================= */}
      <section className="about about--style1 ">
        <div className="container">
          <div className="about__wrapper">
            <div className="row gx-5  gy-4 gy-sm-0  align-items-center">
              <div className="col-lg-6">
                <div
                  className="about__thumb pe-lg-5"
                  data-aos="fade-right"
                  data-aos-duration={800}
                >
                  <div className="about__thumb-inner">
                    <div className="about__thumb-image floating-content">
                      <img
                        className="dark"
                        src="/assets/images/about/1.png"
                        alt="about-image"
                      />
                      <div
                        className="floating-content__top-left"
                        data-aos="fade-right"
                        data-aos-duration={1000}
                      >
                        <div className="floating-content__item">
                          <h3>
                            {" "}
                            <span
                              className="purecounter"
                              data-purecounter-start={0}
                              data-purecounter-end={10}
                            >
                              30
                            </span>
                            Years
                          </h3>
                          <p>Consulting Experience</p>
                        </div>
                      </div>
                      <div
                        className="floating-content__bottom-right"
                        data-aos="fade-right"
                        data-aos-duration={1000}
                      >
                        <div className="floating-content__item">
                          <h3>
                            {" "}
                            <span
                              className="purecounter"
                              data-purecounter-start={0}
                              data-purecounter-end={25}
                            >
                              25K
                            </span>
                            K+
                          </h3>
                          <p>Satisfied Customers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="about__content"
                  data-aos="fade-left"
                  data-aos-duration={800}
                >
                  <div className="about__content-inner">
                    <h2>
                      Meet <span>our company</span> unless miss the opportunity{" "}
                    </h2>
                    <p className="mb-0">
                      Hey there! So glad you stopped by to Meet Our Company. Don't
                      miss out on this opportunity to learn about what we do and the
                      amazing team that makes it all happen! Our company is all
                      about creating innovative solutions and providing top-notch
                      services to our clients. From start to finish, we're dedicated
                      to delivering results that exceed expectations.{" "}
                    </p>
                    <a
                      href="about.html"
                      className="trk-btn trk-btn--border trk-btn--primary"
                    >
                      Explore More{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ===============>> About section start here <<================= */}
      {/* ===============>> feature section start here <<================= */}
      <section className="feature feature--style1 padding-bottom padding-top bg-color">
        <div className="container">
          <div className="feature__wrapper">
            <div className="row g-5 align-items-center justify-content-between">
              <div className="col-md-6 col-lg-5">
                <div
                  className="feature__content"
                  data-aos="fade-right"
                  data-aos-duration={800}
                >
                  <div className="feature__content-inner">
                    <div className="section-header">
                      <h2 className="mb-10 mt-minus-5">
                        {" "}
                        <span>benefits </span>We offer
                      </h2>
                      <p className="mb-0">
                        Unlock the full potential of our product with our amazing
                        features and top-notch.
                      </p>
                    </div>
                    <div className="feature__nav">
                      <div
                        className="nav nav--feature flex-column nav-pills"
                        id="feat-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <div
                          className="nav-link active"
                          id="feat-pills-one-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#feat-pills-one"
                          role="tab"
                          aria-controls="feat-pills-one"
                          aria-selected="true"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  Lending money for investment of your new projects
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nav-link"
                          id="feat-pills-two-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#feat-pills-two"
                          role="tab"
                          aria-controls="feat-pills-two"
                          aria-selected="false"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  more Security and control over money from the rest
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nav-link"
                          id="feat-pills-three-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#feat-pills-three"
                          role="tab"
                          aria-controls="feat-pills-three"
                          aria-selected="false"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  Mobile payment is more flexible and easy for all
                                  investors
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nav-link"
                          id="feat-pills-four-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#feat-pills-four"
                          role="tab"
                          aria-controls="feat-pills-four"
                          aria-selected="false"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  all transaction is kept free for the member of pro
                                  traders
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div
                  className="feature__thumb pt-5 pt-md-0"
                  data-aos="fade-left"
                  data-aos-duration={800}
                >
                  <div className="feature__thumb-inner">
                    <div className="tab-content" id="feat-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="feat-pills-one"
                        role="tabpanel"
                        aria-labelledby="feat-pills-one-tab"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            src="/assets/images/feature/1.png"
                            alt="Feature image"
                          />
                          <div
                            className="floating-content__top-right floating-content__top-right--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style2 text-center">
                              <img src="/assets/images/feature/5.png" alt="rating" />
                              <p className="style2">Interest Rate For Loan</p>
                            </div>
                          </div>
                          <div
                            className="floating-content__bottom-left floating-content__bottom-left--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style3  d-flex align-items-center">
                              <h3 className="style2">
                                {" "}
                                <span
                                  className="purecounter"
                                  data-purecounter-start={0}
                                  data-purecounter-end={10}
                                >
                                  10M
                                </span>
                                M
                              </h3>
                              <p className="ms-3 style2">Available for loan</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="feat-pills-two"
                        role="tabpanel"
                        aria-labelledby="feat-pills-two-tab"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            src="/assets/images/feature/2.png"
                            alt="Feature image"
                          />
                          <div
                            className="floating-content__top-right floating-content__top-right--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style2 text-center">
                              <img src="/assets/images/feature/6.png" alt="rating" />
                              <p className="style2">Interest Rate For Loan</p>
                            </div>
                          </div>
                          <div
                            className="floating-content__bottom-left floating-content__bottom-left--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style3  d-flex align-items-center">
                              <h3 className="style2">
                                {" "}
                                <span
                                  className="purecounter"
                                  data-purecounter-start={0}
                                  data-purecounter-end={18}
                                >
                                  10M
                                </span>
                                M
                              </h3>
                              <p className="ms-3 style2">Available for loan</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="feat-pills-three"
                        role="tabpanel"
                        aria-labelledby="feat-pills-three-tab"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            src="/assets/images/feature/1.png"
                            alt="Feature image"
                          />
                          <div
                            className="floating-content__top-right floating-content__top-right--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style2 text-center">
                              <img src="/assets/images/feature/7.png" alt="rating" />
                              <p className="style2">Interest Rate For Loan</p>
                            </div>
                          </div>
                          <div
                            className="floating-content__bottom-left floating-content__bottom-left--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style3  d-flex align-items-center">
                              <h3 className="style2">
                                {" "}
                                <span
                                  className="purecounter"
                                  data-purecounter-start={0}
                                  data-purecounter-end={30}
                                >
                                  10M
                                </span>
                                M
                              </h3>
                              <p className="ms-3 style2">Available for loan</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="feat-pills-four"
                        role="tabpanel"
                        aria-labelledby="feat-pills-four-tab"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            src="/assets/images/feature/2.png"
                            alt="Feature image"
                          />
                          <div
                            className="floating-content__top-right floating-content__top-right--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style2 text-center">
                              <img src="/assets/images/feature/8.png" alt="rating" />
                              <p className="style2">Interest Rate For Loan</p>
                            </div>
                          </div>
                          <div
                            className="floating-content__bottom-left floating-content__bottom-left--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style3  d-flex align-items-center">
                              <h3 className="style2">
                                {" "}
                                <span
                                  className="purecounter"
                                  data-purecounter-start={0}
                                  data-purecounter-end={28}
                                >
                                  10M
                                </span>
                                M
                              </h3>
                              <p className="ms-3 style2">Available for loan</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="feature__shape">
          <span className="feature__shape-item feature__shape-item--1">
            <img src="/assets/images/feature/shape/1.png" alt="shape-icon" />
          </span>
          <span className="feature__shape-item feature__shape-item--2">
            {" "}
            <span />{" "}
          </span>
        </div>
      </section>
      {/* ===============>> feature section end here <<================= */}
      {/* ===============>> Service section start here <<================= */}
      <section className="service padding-top padding-bottom">
        <div className="section-header section-header--max50">
          <h2 className="mb-10 mt-minus-5">
            <span>services </span>We offer
          </h2>
          <p>
            We offer the best services around - from installations to repairs,
            maintenance, and more!
          </p>
        </div>
        <div className="container">
          <div className="service__wrapper">
            <div className="row g-4 align-items-center">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div
                  className="service__item service__item--style1"
                  data-aos="fade-up"
                  data-aos-duration={800}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img
                        className="dark"
                        src="/assets/images/service/1.png"
                        alt="service-icon"
                      />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        {" "}
                        <a className="stretched-link" href="service-details.html">
                          Strategy Consulting
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        A social assistant that's flexible can accommodate your
                        schedule and needs, making life easier.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style1"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img
                        className="dark"
                        src="/assets/images/service/2.png"
                        alt="service-icon"
                      />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        {" "}
                        <a className="stretched-link" href="service-details.html">
                          {" "}
                          Financial Advisory
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        Modules transform smart trading by automating processes and
                        improving decision-making.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style1"
                  data-aos="fade-up"
                  data-aos-duration={1200}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img
                        className="dark"
                        src="/assets/images/service/3.png"
                        alt="service-icon"
                      />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        {" "}
                        <a className="stretched-link" href="service-details.html">
                          Management
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        There, it's me, your friendly neighborhood reporter's news
                        analyst processes and improving
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style1"
                  data-aos="fade-up"
                  data-aos-duration={800}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img
                        className="dark"
                        src="/assets/images/service/4.png"
                        alt="service-icon"
                      />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        {" "}
                        <a className="stretched-link" href="service-details.html">
                          Supply Optimization{" "}
                        </a>
                      </h5>
                      <p className="mb-0">
                        Hey, have you checked out that new cryptocurrency platform?
                        It's pretty cool and easy to use!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style1"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img
                        className="dark"
                        src="/assets/images/service/5.png"
                        alt="service-icon"
                      />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        {" "}
                        <a className="stretched-link" href="service-details.html">
                          HR consulting
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        Hey guys, just a quick update on exchange orders. There have
                        been some changes currency!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style1"
                  data-aos="fade-up"
                  data-aos-duration={1200}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img
                        className="dark"
                        src="/assets/images/service/6.png"
                        alt="service-icon"
                      />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        {" "}
                        <a className="stretched-link" href="service-details.html">
                          Marketing consulting
                        </a>
                      </h5>
                      <p className="mb-0">
                        Hey! Just wanted to let you know that the price notification
                        module processes is now live!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ===============>> Service section start here <<================= */}
      {/* ========== Roadmap Section start Here========== */}
      <section
        className="roadmap roadmap--style1 padding-top  padding-bottom bg-color"
        id="roadmap"
      >
        <div className="container">
          <div className="section-header section-header--max50">
            <h2 className="mb-10 mt-minus-5">
              Product <span> roadmap</span>
            </h2>
            <p>
              A product roadmap shows the path ahead, helps teams plan, and guides
              the delivery of the product.
            </p>
          </div>
          <div className="roadmap__wrapper">
            <div className="row gy-4 gy-md-0 gx-5">
              <div className="col-md-6 offset-md-6">
                <div
                  className="roadmap__item ms-md-4 aos-init aos-animate"
                  data-aos="fade-left"
                  data-aos-duration={800}
                >
                  <div className="roadmap__item-inner">
                    <div className="roadmap__item-content">
                      <div className="roadmap__item-header">
                        <h3>project research</h3>
                        <span>P1</span>
                      </div>
                      <p>
                        Project research is the backbone of any successful project.
                        It involves gathering information, setting objectives, and
                        analyzing data to ensure the project is achievable. Without
                        proper research, projects can fail due to lack of knowledge.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="roadmap__item roadmap__item--style2 ms-auto me-md-4 aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-duration={800}
                >
                  <div className="roadmap__item-inner">
                    <div className="roadmap__item-content">
                      <div className="roadmap__item-header">
                        <h3>framing idea</h3>
                        <span>P2</span>
                      </div>
                      <p>
                        Hey there! Just wanted to throw out a framing idea for our
                        upcoming project. Let's start with the big picture and then
                        work our way down to the details. This way we ensure our
                        vision stays on track while also ironing out.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-6">
                <div
                  className="roadmap__item ms-md-4 aos-init"
                  data-aos="fade-left"
                  data-aos-duration={800}
                >
                  <div className="roadmap__item-inner">
                    <div className="roadmap__item-content">
                      <div className="roadmap__item-header">
                        <h3>Design first draft</h3>
                        <span>P3</span>
                      </div>
                      <p>
                        Hey guys, just wanted to share my design first draft! I went
                        for a minimalist look with a pop of color. What do you
                        think? Open to any suggestions or feedback. Can't wait to
                        see how this turns out over all kind of success!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="roadmap__item roadmap__item--style2 ms-auto me-md-4 aos-init"
                  data-aos="fade-right"
                  data-aos-duration={800}
                >
                  <div className="roadmap__item-inner">
                    <div className="roadmap__item-content">
                      <div className="roadmap__item-header">
                        <h3>Final design</h3>
                        <span>P4</span>
                      </div>
                      <p>
                        So after lots of reiterations, and endless tweaking, we
                        finally landed on the final design. It's slick, it's modern,
                        and it perfectly captures the essence of what we were aiming
                        for. We couldn't be more excited to launch it!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 offset-md-6">
                <div
                  className="roadmap__item ms-md-4 aos-init"
                  data-aos="fade-left"
                  data-aos-duration={800}
                >
                  <div className="roadmap__item-inner">
                    <div className="roadmap__item-content">
                      <div className="roadmap__item-header">
                        <h3>Project development</h3>
                        <span>P5</span>
                      </div>
                      <p>
                        So we're deep in project development here, and things are
                        coming along nicely. We've got a solid plan in place and our
                        team is firing on all cylinders. There's still work to be
                        done, but we're excited about what we're building.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="roadmap__item roadmap__item--style2 ms-auto me-md-4 aos-init"
                  data-aos="fade-right"
                  data-aos-duration={800}
                >
                  <div className="roadmap__item-inner">
                    <div className="roadmap__item-content">
                      <div className="roadmap__item-header">
                        <h3>Launch project</h3>
                        <span>P6</span>
                      </div>
                      <p>
                        Alright folks, it's time to get this project off the ground!
                        We've been talking about it for weeks, and now it's time to
                        launch. We've got all the pieces in place, so let's get to
                        work and make this thing happen!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="roadmap__shape">
          <span className="roadmap__shape-item roadmap__shape-item--1">
            {" "}
            <span />{" "}
          </span>
          <span className="roadmap__shape-item roadmap__shape-item--2">
            {" "}
            <img src="/assets/images/icon/1.png" alt="shape-icon" />
          </span>
        </div>
      </section>
      {/* ========== Roadmap Section Ends Here========== */}
      {/* ===============>> Pricing section start here <<================= */}
      <section className="pricing padding-top padding-bottom">
        <div className="section-header section-header--max50">
          <h2 className="mb-10 mt-minus-5">
            Our <span>pricings </span>plan
          </h2>
          <p>
            We offer the best pricings around - from installations to repairs,
            maintenance, and more!
          </p>
        </div>
        <div className="container">
          <div className="pricing__wrapper">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  <div className="pricing__item-inner">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">Basic</h6>
                        <h3 className="mb-25">
                          $99/<span>Monthly</span>{" "}
                        </h3>
                      </div>
                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Weekly online meeting
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Unlimited learning access
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Personal training
                          </li>
                        </ul>
                      </div>
                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a href="signup.html" className="trk-btn trk-btn--outline">
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item "
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="pricing__item-inner active">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">standard</h6>
                        <h3 className="mb-25">
                          $149/<span>Monthly</span>{" "}
                        </h3>
                      </div>
                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Weekly online meeting
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Unlimited learning access
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Personal training
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Business analysis
                          </li>
                        </ul>
                      </div>
                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a
                          href="signup.html"
                          className="trk-btn trk-btn--outline active"
                        >
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <div className="pricing__item-inner">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">premium</h6>
                        <h3 className="mb-25">
                          $199/<span>Monthly</span>{" "}
                        </h3>
                      </div>
                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            With all standard features
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Unlimited learning access
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="/assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Personal training
                          </li>
                        </ul>
                      </div>
                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a href="signup.html" className="trk-btn trk-btn--outline">
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing__shape">
          <span className="pricing__shape-item pricing__shape-item--1">
            {" "}
            <span />{" "}
          </span>
          <span className="pricing__shape-item pricing__shape-item--2">
            {" "}
            <img src="/assets/images/icon/1.png" alt="shape-icon" />
          </span>
        </div>
      </section>
      {/* ===============>> Pricing section start here <<================= */}
      {/* ===============>> Team section start here <<================= */}
      <section className="team padding-top padding-bottom bg-color">
        <div className="section-header section-header--max50">
          <h2 className="mb-10 mt-minus-5">
            Meet our <span>advisers</span>
          </h2>
          <p>
            Hey everyone, meet our amazing advisers! They're here to help and guide
            us through anything.
          </p>
        </div>
        <div className="container">
          <div className="team__wrapper">
            <div className="row g-4 align-items-center">
              <div className="col-sm-6 col-lg-3">
                <div
                  className="team__item team__item--shape"
                  data-aos="fade-up"
                  data-aos-duration={800}
                >
                  <div className="team__item-inner team__item-inner--shape">
                    <div className="team__item-thumb team__item-thumb--style1">
                      <img
                        src="/assets/images/team/1.png"
                        alt="Team Image"
                        className="dark"
                      />
                    </div>
                    <div className="team__item-content team__item-content--style1">
                      <div className="team__item-author team__item-author--style1">
                        <div className="team__item-authorinfo">
                          <h6 className="mb-1">
                            <a href="team-details.html" className="stretched-link">
                              Dianne Russell
                            </a>{" "}
                          </h6>
                          <p className="mb-0">Trade Captain</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="team__item team__item--shape"
                  data-aos="fade-up"
                  data-aos-duration={900}
                >
                  <div className="team__item-inner team__item-inner--shape">
                    <div className="team__item-thumb team__item-thumb--style1">
                      <img
                        src="/assets/images/team/2.png"
                        alt="Team Image"
                        className="dark"
                      />
                    </div>
                    <div className="team__item-content team__item-content--style1">
                      <div className="team__item-author team__item-author--style1">
                        <div className="team__item-authorinfo">
                          <h6 className="mb-1">
                            <a href="team-details.html" className="stretched-link">
                              Theresa Webb
                            </a>{" "}
                          </h6>
                          <p className="mb-0">Strategic Advisor</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="team__item team__item--shape"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="team__item-inner team__item-inner--shape">
                    <div className="team__item-thumb team__item-thumb--style1">
                      <img
                        src="/assets/images/team/3.png"
                        alt="Team Image"
                        className="dark"
                      />
                    </div>
                    <div className="team__item-content team__item-content--style1">
                      <div className="team__item-author team__item-author--style1">
                        <div className="team__item-authorinfo">
                          <h6 className="mb-1">
                            <a href="team-details.html" className="stretched-link">
                              Courtney Henry
                            </a>{" "}
                          </h6>
                          <p className="mb-0">Management Consultant</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="team__item team__item--shape"
                  data-aos="fade-up"
                  data-aos-duration={1100}
                >
                  <div className="team__item-inner team__item-inner--shape">
                    <div className="team__item-thumb team__item-thumb--style1">
                      <img
                        src="/assets/images/team/4.png"
                        alt="Team Image"
                        className="dark"
                      />
                    </div>
                    <div className="team__item-content team__item-content--style1">
                      <div className="team__item-author team__item-author--style1">
                        <div className="team__item-authorinfo">
                          <h6 className="mb-1">
                            <a href="team-details.html" className="stretched-link">
                              Albert Flores
                            </a>{" "}
                          </h6>
                          <p className="mb-0">Development Specialist</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="team__item team__item--shape"
                  data-aos="fade-up"
                  data-aos-duration={800}
                >
                  <div className="team__item-inner team__item-inner--shape">
                    <div className="team__item-thumb team__item-thumb--style1">
                      <img
                        src="/assets/images/team/5.png"
                        alt="Team Image"
                        className="dark"
                      />
                    </div>
                    <div className="team__item-content team__item-content--style1">
                      <div className="team__item-author team__item-author--style1">
                        <div className="team__item-authorinfo">
                          <h6 className="mb-1">
                            <a href="team-details.html" className="stretched-link">
                              Darrell Steward
                            </a>{" "}
                          </h6>
                          <p className="mb-0">Growth Strategist</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="team__item team__item--shape"
                  data-aos="fade-up"
                  data-aos-duration={900}
                >
                  <div className="team__item-inner team__item-inner--shape">
                    <div className="team__item-thumb team__item-thumb--style1">
                      <img
                        src="/assets/images/team/6.png"
                        alt="Team Image"
                        className="dark"
                      />
                    </div>
                    <div className="team__item-content team__item-content--style1">
                      <div className="team__item-author team__item-author--style1">
                        <div className="team__item-authorinfo">
                          <h6 className="mb-1">
                            <a href="team-details.html" className="stretched-link">
                              Wade Warren
                            </a>{" "}
                          </h6>
                          <p className="mb-0">Trade Consultant</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="team__item team__item--shape"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="team__item-inner team__item-inner--shape">
                    <div className="team__item-thumb team__item-thumb--style1">
                      <img
                        src="/assets/images/team/7.png"
                        alt="Team Image"
                        className="dark"
                      />
                    </div>
                    <div className="team__item-content team__item-content--style1">
                      <div className="team__item-author team__item-author--style1">
                        <div className="team__item-authorinfo">
                          <h6 className="mb-1">
                            <a href="team-details.html" className="stretched-link">
                              Cody Fisher
                            </a>{" "}
                          </h6>
                          <p className="mb-0">HR Consultant</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div
                  className="team__item team__item--shape"
                  data-aos="fade-up"
                  data-aos-duration={1100}
                >
                  <div className="team__item-inner team__item-inner--shape">
                    <div className="team__item-thumb team__item-thumb--style1">
                      <img
                        src="/assets/images/team/8.png"
                        alt="Team Image"
                        className="dark"
                      />
                    </div>
                    <div className="team__item-content team__item-content--style1">
                      <div className="team__item-author team__item-author--style1">
                        <div className="team__item-authorinfo">
                          <h6 className="mb-1">
                            <a href="team-details.html" className="stretched-link">
                              Bessie Cooper
                            </a>{" "}
                          </h6>
                          <p className="mb-0">Financial Advisor</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <a
                  href="team.html"
                  className="trk-btn trk-btn--border trk-btn--primary mt-25"
                >
                  View more{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ===============>> Team section start here <<================= */}
      {/* ===============>> Blog section start here <<================= */}
      <section className="blog padding-top padding-bottom">
        <div className="container">
          <div className="section-header d-md-flex align-items-center justify-content-between">
            <div className="section-header__content">
              <h2 className="mb-10">
                <span>articles</span> for pro traders
              </h2>
              <p className="mb-0">
                Hey there pro traders, check out these articles with tips to take
                your trading game to the next level!
              </p>
            </div>
            <div className="section-header__action">
              <div className="swiper-nav swiper-nav--style1">
                <button className="swiper-nav__btn blog__slider-prev">
                  <i className="fa-solid fa-angle-left" />
                </button>
                <button className="swiper-nav__btn blog__slider-next active">
                  <i className="fa-solid fa-angle-right" />
                </button>
              </div>
            </div>
          </div>
          <div
            className="blog__wrapper"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog__slider swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="blog__item blog__item--style2">
                    <div className="blog__item-inner">
                      <div className="blog__thumb">
                        <img src="/assets/images/blog/1.png" alt="blog Images" />
                      </div>
                      <div className="blog__content">
                        <div className="blog__meta">
                          <span className="blog__meta-tag blog__meta-tag--style1">
                            Forex trading
                          </span>
                        </div>
                        <h5 className={10}>
                          {" "}
                          <a href="blog-details.html">
                            Swing Trading Definition
                          </a>{" "}
                        </h5>
                        <p className="mb-15">
                          Our platform is not only about trading—it's also a hub for
                          knowledge and learning. We provide resources. Our platform
                          is not only about trading—it's also a hub for knowledge
                          and learning. We provide resources.
                        </p>
                        <div className="blog__writer">
                          <div className="blog__writer-thumb">
                            <img
                              src="/assets/images/blog/author/1.png"
                              alt="writer"
                            />
                          </div>
                          <div className="blog__writer-designation">
                            <h6 className="mb-0">Vasha Gueye</h6>
                            <span>20/6/2024</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog__item blog__item--style2">
                    <div className="blog__item-inner">
                      <div className="blog__thumb">
                        <img src="/assets/images/blog/2.png" alt="blog Images" />
                      </div>
                      <div className="blog__content">
                        <div className="blog__meta">
                          <span className="blog__meta-tag blog__meta-tag--style1">
                            Trading market
                          </span>
                        </div>
                        <h5 className={10}>
                          {" "}
                          <a href="blog-details.html">hedge funds work?</a>{" "}
                        </h5>
                        <p className="mb-15">
                          To cater to your individual trading preferences, we offer
                          a variety of order types, including market.
                        </p>
                        <div className="blog__writer">
                          <div className="blog__writer-thumb">
                            <img
                              src="/assets/images/blog/author/2.png"
                              alt="writer"
                            />
                          </div>
                          <div className="blog__writer-designation">
                            <h6 className="mb-0">Abhivibha Kanmani</h6>
                            <span>30/5/2024</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="blog__item blog__item--style2">
                    <div className="blog__item-inner">
                      <div className="blog__thumb">
                        <img src="/assets/images/blog/3.png" alt="blog Images" />
                      </div>
                      <div className="blog__content">
                        <div className="blog__meta">
                          <span className="blog__meta-tag blog__meta-tag--style1">
                            Investment
                          </span>
                        </div>
                        <h5 className={10}>
                          {" "}
                          <a href="blog-details.html">
                            Options Trading business?
                          </a>{" "}
                        </h5>
                        <p className="mb-15">
                          Security is our top priority, and we employ robust
                          measures to ensure the safety of your funds.
                        </p>
                        <div className="blog__writer">
                          <div className="blog__writer-thumb">
                            <img
                              src="/assets/images/blog/author/3.png"
                              alt="writer"
                            />
                          </div>
                          <div className="blog__writer-designation">
                            <h6 className="mb-0">Hulya Aydin</h6>
                            <span>12/07/2024</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <a
              href="blogs.html"
              className="trk-btn trk-btn--border trk-btn--primary mt-15"
            >
              View more{" "}
            </a>
          </div>
        </div>
        <div className="blog__shape">
          <span className="blog__shape-item blog__shape-item--1">
            {" "}
            <span />{" "}
          </span>
        </div>
      </section>
      {/* ===============>> Blog section start here <<================= */}
      {/* ===============>> Testimonial section start here <<================= */}
      <section className="testimonial padding-top padding-bottom-style2 bg-color">
        <div className="container">
          <div className="section-header d-md-flex align-items-center justify-content-between">
            <div className="section-header__content">
              <h2 className="mb-10">
                connect with <span>our Clients </span>
              </h2>
              <p className="mb-0">
                We love connecting with our clients to hear about their experiences
                and how we can improve.
              </p>
            </div>
            <div className="section-header__action">
              <div className="swiper-nav">
                <button className="swiper-nav__btn testimonial__slider-prev">
                  <i className="fa-solid fa-angle-left" />
                </button>
                <button className="swiper-nav__btn testimonial__slider-next active">
                  <i className="fa-solid fa-angle-right" />
                </button>
              </div>
            </div>
          </div>
          <div
            className="testimonial__wrapper"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="testimonial__slider swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial__item testimonial__item--style1">
                    <div className="testimonial__item-inner">
                      <div className="testimonial__item-content">
                        <p className="mb-0">
                          The above testimonial is about Martha Chumo, who taught
                          herself to code in one summer. This testimonial example
                          works because it allows prospective customers to see
                          themselves in Codeacademy’s current customer base.
                        </p>
                        <div className="testimonial__footer">
                          <div className="testimonial__author">
                            <div className="testimonial__author-thumb">
                              <img
                                src="/assets/images/testimonial/1.png"
                                alt="author"
                              />
                            </div>
                            <div className="testimonial__author-designation">
                              <h6>Mobarok Hossain</h6>
                              <span>Trade Master</span>
                            </div>
                          </div>
                          <div className="testimonial__quote">
                            <span>
                              <i className="fa-solid fa-quote-right" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial__item testimonial__item--style1">
                    <div className="testimonial__item-inner">
                      <div className="testimonial__item-content">
                        <p className="mb-0">
                          In the above testimonial, a customer named Jeanine shares
                          her experience with Briogeo’s products. While the post is
                          scattered with too many product mentions, it takes full
                          advantage of its real estate by allowing the writer to
                          tell
                        </p>
                        <div className="testimonial__footer">
                          <div className="testimonial__author">
                            <div className="testimonial__author-thumb">
                              <img
                                src="/assets/images/testimonial/2.png"
                                alt="author"
                              />
                            </div>
                            <div className="testimonial__author-designation">
                              <h6>Guy Hawkins</h6>
                              <span>Trade Boss</span>
                            </div>
                          </div>
                          <div className="testimonial__quote">
                            <span>
                              <i className="fa-solid fa-quote-right" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="testimonial__item testimonial__item--style1">
                    <div className="testimonial__item-inner">
                      <div className="testimonial__item-content">
                        <p className="mb-0">
                          The above testimonial is about Martha Chumo, who taught
                          herself to code in one summer. This testimonial example
                          works because it allows prospective customers to see
                          themselves in Codeacademy’s current customer base.
                        </p>
                        <div className="testimonial__footer">
                          <div className="testimonial__author">
                            <div className="testimonial__author-thumb">
                              <img
                                src="/assets/images/testimonial/6.png"
                                alt="author"
                              />
                            </div>
                            <div className="testimonial__author-designation">
                              <h6>Belal Hossain</h6>
                              <span>Trade Genius</span>
                            </div>
                          </div>
                          <div className="testimonial__quote">
                            <span>
                              <i className="fa-solid fa-quote-right" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ===============>> Testimonial section start here <<================= */}
      {/* ===============>> FAQ section start here <<================= */}
      <section className="faq padding-top padding-bottom of-hidden">
        <div className="section-header section-header--max65">
          <h2 className="mb-10 mt-minus-5">
            <span>Frequently</span> Asked questions
          </h2>
          <p>
            Hey there! Got questions? We've got answers. Check out our FAQ page for
            all the deets. Still not satisfied? Hit us up.
          </p>
        </div>
        <div className="container">
          <div className="faq__wrapper">
            <div className="row g-5 align-items-center justify-content-between">
              <div className="col-lg-6">
                <div
                  className="accordion accordion--style1"
                  id="faqAccordion1"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="accordion__item accordion-item">
                        <div
                          className="accordion__header accordion-header"
                          id="faq1"
                        >
                          <button
                            className="accordion__button accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody1"
                            aria-expanded="false"
                            aria-controls="faqBody1"
                          >
                            <span className="accordion__button-content">
                              What does this tool do?
                            </span>
                          </button>
                        </div>
                        <div
                          id="faqBody1"
                          className="accordion-collapse collapse show"
                          aria-labelledby="faq1"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body accordion-body">
                            <p className="mb-15">
                              Online trading’s primary advantages are that it allows
                              you to manage your trades at your convenience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item accordion-item">
                        <div
                          className="accordion__header accordion-header"
                          id="faq2"
                        >
                          <button
                            className="accordion-button accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody2"
                            aria-expanded="true"
                            aria-controls="faqBody2"
                          >
                            <span className=" accordion__button-content">
                              What are the disadvantages of online trading?
                            </span>
                          </button>
                        </div>
                        <div
                          id="faqBody2"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq2"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body accordion-body">
                            <p className="mb-15">
                              You don’t need to worry, the interface is
                              user-friendly. Anyone can use it smoothly. Our user
                              manual will help you to solve your problem.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item accordion-item">
                        <div
                          className="accordion__header accordion-header"
                          id="faq3"
                        >
                          <button
                            className="accordion-button accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody3"
                            aria-expanded="false"
                            aria-controls="faqBody3"
                          >
                            <span className="accordion__button-content">
                              Is online trading safe?
                            </span>
                          </button>
                        </div>
                        <div
                          id="faqBody3"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq3"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body accordion-body">
                            <p className="mb-15">
                              {" "}
                              Online trading’s primary advantages are that it allows
                              you to manage your trades at your convenience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item accordion-item">
                        <div
                          className="accordion__header accordion-header"
                          id="faq4"
                        >
                          <button
                            className="accordion-button accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody4"
                            aria-expanded="false"
                            aria-controls="faqBody4"
                          >
                            <span className="accordion__button-content">
                              What is online trading, and how does it work?
                            </span>
                          </button>
                        </div>
                        <div
                          id="faqBody4"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq4"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body accordion-body">
                            <p className="mb-15">
                              {" "}
                              Online trading’s primary advantages are that it allows
                              you to manage your trades at your convenience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item accordion-item">
                        <div
                          className="accordion__header accordion-header"
                          id="faq5"
                        >
                          <button
                            className="accordion-button accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody5"
                            aria-expanded="false"
                            aria-controls="faqBody5"
                          >
                            <span className="accordion__button-content">
                              Which app is best for online trading?
                            </span>
                          </button>
                        </div>
                        <div
                          id="faqBody5"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq5"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body accordion-body">
                            <p className="mb-15">
                              {" "}
                              Online trading’s primary advantages are that it allows
                              you to manage your trades at your convenience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item accordion-item border-0">
                        <div
                          className="accordion__header accordion-header"
                          id="faq6"
                        >
                          <button
                            className="accordion-button accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody6"
                            aria-expanded="false"
                            aria-controls="faqBody6"
                          >
                            <span className="accordion__button-content">
                              {" "}
                              How to create a trading account?
                            </span>
                          </button>
                        </div>
                        <div
                          id="faqBody6"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq6"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body accordion-body">
                            <p className="mb-15">
                              {" "}
                              Online trading’s primary advantages are that it allows
                              you to manage your trades at your convenience.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="faq__thumb faq__thumb--style1"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img
                    className="dark"
                    src="/assets/images/others/1.png"
                    alt="faq-thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq__shape faq__shape--style1">
          <span className="faq__shape-item faq__shape-item--1">
            <img src="/assets/images/others/2.png" alt="shpae-icon" />
          </span>
        </div>
      </section>
      {/* ===============>> FAQ section start here <<================= */}
      {/* ===============>> cta section start here <<================= */}
      <section className="cta padding-top padding-bottom  bg-color">
        <div className="container">
          <div className="cta__wrapper">
            <div className="cta__newsletter justify-content-center">
              <div
                className="cta__newsletter-inner"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <div className="cta__thumb">
                  <img src="/assets/images/cta/3.png" alt="cta-thumb" />
                </div>
                <div className="cta__subscribe">
                  <h2>
                    {" "}
                    <span>Subscribe</span> our news
                  </h2>
                  <p>
                    Hey! Are you tired of missing out on our updates? Subscribe to
                    our news now and stay in the loop!
                  </p>
                  <form
                    className="cta-form cta-form--style2 form-subscribe"
                    action="#"
                  >
                    <div className="cta-form__inner d-sm-flex align-items-center">
                      <input
                        type="email"
                        className="form-control form-control--style2 mb-3 mb-sm-0"
                        placeholder="Email Address"
                      />
                      <button
                        className="trk-btn  trk-btn--large trk-btn--secondary2"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="cta__shape">
              <span className="cta__shape-item cta__shape-item--1">
                <img src="/assets/images/cta/2.png" alt="shape icon" />
              </span>
              <span className="cta__shape-item cta__shape-item--2">
                <img src="/assets/images/cta/4.png" alt="shape icon" />
              </span>
              <span className="cta__shape-item cta__shape-item--3">
                <img src="/assets/images/cta/5.png" alt="shape icon" />
              </span>
            </div>
          </div>
        </div>
      </section>
      {/* ===============>> cta section start here <<================= */}
      {/* ===============>> footer start here <<================= */}
      <footer className="footer ">
        <div className="container">
          <div className="footer__wrapper">
            <div className="footer__top footer__top--style1">
              <div className="row gy-5 gx-4">
                <div className="col-md-6">
                  <div className="footer__about">
                    <a href="index-2.html" className="footer__about-logo">
                      <img src="/assets/images/logo/logo.png" alt="Logo" />
                    </a>
                    <p className="footer__about-moto ">
                      Welcome to our trading site! We offer the best, most
                      affordable products and services around. Shop now and start
                      finding great deals!
                    </p>
                    <div className="footer__app">
                      <div className="footer__app-item footer__app-item--apple">
                        <div className="footer__app-inner">
                          <div className="footer__app-thumb">
                            <a
                              href="https://www.apple.com/app-store/"
                              target="_blank"
                              className="stretched-link"
                            >
                              <img
                                src="/assets/images/footer/apple.png"
                                alt="apple-icon"
                              />
                            </a>
                          </div>
                          <div className="footer__app-content">
                            <span>Download on the</span>
                            <p className="mb-0">App Store</p>
                          </div>
                        </div>
                      </div>
                      <div className="footer__app-item footer__app-item--playstore">
                        <div className="footer__app-inner">
                          <div className="footer__app-thumb">
                            <a
                              href="https://play.google.com/store"
                              target="_blank"
                              className="stretched-link"
                            >
                              <img
                                src="/assets/images/footer/play.png"
                                alt="playstore-icon"
                              />
                            </a>
                          </div>
                          <div className="footer__app-content">
                            <span>GET IT ON</span>
                            <p className="mb-0">Google Play</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-sm-4 col-6">
                  <div className="footer__links">
                    <div className="footer__links-tittle">
                      <h6>Quick links</h6>
                    </div>
                    <div className="footer__links-content">
                      <ul className="footer__linklist">
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="about.html">About Us</a>
                        </li>
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="team.html">Teams</a>
                        </li>
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="service.html">Services</a>{" "}
                        </li>
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="#">Features</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-sm-4 col-6">
                  <div className="footer__links">
                    <div className="footer__links-tittle">
                      <h6>Support</h6>
                    </div>
                    <div className="footer__links-content">
                      <ul className="footer__linklist">
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="#">Terms &amp; Conditions</a>
                        </li>
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="#">Privacy Policy</a>
                        </li>
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="#">FAQs</a>
                        </li>
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="#">Support Center</a>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-sm-4">
                  <div className="footer__links">
                    <div className="footer__links-tittle">
                      <h6>Company</h6>
                    </div>
                    <div className="footer__links-content">
                      <ul className="footer__linklist">
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="#">Careers</a>
                        </li>
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="#">Updates</a>
                        </li>
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="#">Job</a>{" "}
                        </li>
                        <li className="footer__linklist-item">
                          {" "}
                          <a href="#">Announce</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__bottom">
              <div className="footer__end">
                <div className="footer__end-copyright">
                  <p className=" mb-0">
                    © 2024 All Rights Reserved By{" "}
                    <a
                      href="https://themeforest.net/user/thetork/portfolio"
                      target="_blank"
                    >
                      Thetork
                    </a>{" "}
                  </p>
                </div>
                <div>
                  <ul className="social">
                    <li className="social__item">
                      <a href="#" className="social__link social__link--style22">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li className="social__item">
                      <a href="#" className="social__link social__link--style22 ">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li className="social__item">
                      <a href="#" className="social__link social__link--style22">
                        <i className="fa-brands fa-linkedin-in" />
                      </a>
                    </li>
                    <li className="social__item">
                      <a href="#" className="social__link social__link--style22">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li className="social__item">
                      <a href="#" className="social__link social__link--style22 ">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__shape">
          <span className="footer__shape-item footer__shape-item--1">
            <img src="/assets/images/footer/1.png" alt="shape icon" />
          </span>
          <span className="footer__shape-item footer__shape-item--2">
            {" "}
            <span />{" "}
          </span>
        </div>
      </footer>
      {/* ===============>> footer end here <<================= */}
      {/* ===============>> scrollToTop start here <<================= */}
      <a href="#" className="scrollToTop scrollToTop--style1">
        <i className="fa-solid fa-arrow-up-from-bracket" />
      </a>
      {/* ===============>> scrollToTop ending here <<================= */}
      {/* vendor plugins */}
    </div>
  );
}

export default App;
